<template>
  <div class="sign-in">
    <div
      class="form-box"
      v-if="authReady && !authUser"
      :style="{
        background: '#FFF',
        border: '1px solid #e4e4e4',
        borderRadius: '4px',
        marginBottom: '30px',
        boxShadow: '0px 0px 1px 1px inset #ECECEC'
      }"
    >
      <h3 tabindex="0" role="heading">{{ $t('auth.pleaseSignIn') }}</h3>

      <form id="sign-in-form" @submit.prevent="onSubmit">
        <b-field>
          <b-input
            :placeholder="$t('auth.email')"
            v-model="email"
            type="email"
            icon="email"
            tests-id="email"
          />
        </b-field>

        <b-field>
          <b-input
            tests-id="password"
            :placeholder="$t('auth.password')"
            v-model="password"
            type="password"
            icon="lock"
            autocomplete="current-password"
            v-on:keyup.enter="onSubmit"
          />
        </b-field>
        <div class="field">
          <transition name="fade" appear>
            <div class="alert alert-warning" role="alert" v-if="authError">
              {{ authError }}
            </div>
          </transition>
        </div>

        <div class="field">
          <input
            type="submit"
            class="button is-primary is-pulled-right"
            :disabled="authLoading"
            value="Sign In"
            tests-id="sign-in-button"
          />
        </div>
      </form>
    </div>
    <div>
      <router-link
        :to="{
          name: 'forgotpassword'
        }"
        class="href-overflow-wrap is-primary is-medium"
        >Forgot Password</router-link
      >
    </div>

    <div>
      <router-link
        :to="{
          name: 'signupWithCodeVendor'
        }"
        class="href-overflow-wrap is-primary is-medium"
        >Vendor Signup {{ appId }}</router-link
      >
    </div>

    <!--
    <div class="form-box">
      <h5>Not yet registered?</h5>
      <router-link to="/register" class="button">Create account</router-link>
    </div> -->
  </div>
</template>

<script type="es6">
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    email: '',
    password: ''
  }),

  metaInfo: {
    title: 'Sign In'
  },

  watch: {
    authUser(value) {
      this.checkForUser(value)
    }
  },

  computed: {
    ...mapGetters('user', ['authUser', 'appId', 'authReady', 'authError', 'authLoading'])
  },

  mounted() {
    this.checkForUser(this.authUser)

    const newRegistrationEmail = this.$route.query.email
    if(newRegistrationEmail && newRegistrationEmail!=undefined)
    {
      this.email = newRegistrationEmail
    }
  },

  methods: {
    ...mapActions('user', ['login']),

    checkForUser(value) {
      if (value !== null && value !== undefined) {
        this.$router.replace(this.$route.query.nextUrl || '/?fromsignin=true')
      }
    },

    onSubmit() {
      const { email, password } = this
      this.login({ email, password })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-box {
  max-width: 480px;
}

.sign-in {
  display: flex;
  height: 100%;
  min-height: 75vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in"},[(_vm.authReady && !_vm.authUser)?_c('div',{staticClass:"form-box",style:({
      background: '#FFF',
      border: '1px solid #e4e4e4',
      borderRadius: '4px',
      marginBottom: '30px',
      boxShadow: '0px 0px 1px 1px inset #ECECEC'
    })},[_c('h3',{attrs:{"tabindex":"0","role":"heading"}},[_vm._v(_vm._s(_vm.$t('auth.pleaseSignIn')))]),_c('form',{attrs:{"id":"sign-in-form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-field',[_c('b-input',{attrs:{"placeholder":_vm.$t('auth.email'),"type":"email","icon":"email","tests-id":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('b-field',[_c('b-input',{attrs:{"tests-id":"password","placeholder":_vm.$t('auth.password'),"type":"password","icon":"lock","autocomplete":"current-password"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticClass:"field"},[_c('transition',{attrs:{"name":"fade","appear":""}},[(_vm.authError)?_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.authError)+" ")]):_vm._e()])],1),_c('div',{staticClass:"field"},[_c('input',{staticClass:"button is-primary is-pulled-right",attrs:{"type":"submit","disabled":_vm.authLoading,"value":"Sign In","tests-id":"sign-in-button"}})])],1)]):_vm._e(),_c('div',[_c('router-link',{staticClass:"href-overflow-wrap is-primary is-medium",attrs:{"to":{
        name: 'forgotpassword'
      }}},[_vm._v("Forgot Password")])],1),_c('div',[_c('router-link',{staticClass:"href-overflow-wrap is-primary is-medium",attrs:{"to":{
        name: 'signupWithCodeVendor'
      }}},[_vm._v("Vendor Signup "+_vm._s(_vm.appId))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }